import * as Yup from 'yup'

import { ISubmitStateInterface } from '@commerce/utils/use-data-submit'
import { EmptyGuid, Messages } from '@components/utils/constants'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { GA_EVENT } from 'hooks/ga_event.modal'
import Link from 'next/link'

export const OTP_TIMER = 45
export const LOGIN_FORM_ID = 'otpLogin'

export interface INewLoginFormProps {
  readonly formId: string
  readonly schema: any
  readonly initialValues?: any
  readonly defaultValues?: any
  readonly formFields: Array<any>
  readonly isOTPSent?: boolean
  readonly mobileNumber?: any
  onSubmit?: any
}

export const LOGIN_FORM_SCHEMA = Yup.object().shape({
  mobileNumber: Yup.string()
    .required(Messages.Validations.Login['MOBILE_NUMBER_REQUIRED'])
    .matches(
      Messages.Validations.RegularExpressions.MOBILE_NUMBER,
      Messages.Validations.Login['MOBILE_NUMBER_INPUT']
    )
    .min(10, Messages.Validations.Login['MOBILE_NUMBER_LENGTH'])
    .max(10, Messages.Validations.Login['MOBILE_NUMBER_LENGTH']),
  otp: Yup.number().min(6),
})

export const DEFAULT_LOGIN_VALUES = {
  mobileNumber: '',
  otp: '',
}

export interface INewLoginProps {
  readonly submitState: ISubmitStateInterface
  readonly submitDispatch: any
  readonly show: boolean
  onSubmit: any
  close: Function
  readonly previousPath?: string
}

export const handleOTPGTMEvent = () => {
  recordGA4Event(window, GA_EVENT.OTP_SENT, {
    current_page: 'login-modal',
  })
}

export const calculateLastOrderRecords = (ordersResult: any) => {
  let countTodayOrder = 0
  let countWeekOrder = 0
  let countMonthOrder = 0
  ordersResult?.forEach((item: any) => {
    if (item?.orderType === 12 && item?.orderStatus === 'Delivered') {
      const _d = new Date(item?.orderDate + 'Z').toLocaleString('en-US')
      const __d = new Date().toLocaleString('en-US')
      const orderDate: any = Date.parse(_d)
      const currentDate: any = Date.parse(__d)
      const diffTime = currentDate - orderDate
      const diffSeconds = diffTime / 1000
      if (diffSeconds < 86400) {
        countTodayOrder++
        countWeekOrder++
        countMonthOrder++
      } else if (diffSeconds > 86400 && diffSeconds < 604800) {
        countWeekOrder++
        countMonthOrder++
      } else if (diffSeconds > 604800 && diffSeconds < 2592000) {
        countMonthOrder++
        //do nothing
      }
    }
  })
  return {
    countTodayOrder,
    countWeekOrder,
    countMonthOrder,
  }
}

export const policyPopupEvent = (user: any) => {
  recordGA4Event(window, GA_EVENT.POLICY_POPUP, {
    current_page: 'Header',
    category: 'login Modal',
    loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
  })
}

export const LOGIN_NEW_MODAL_FORM_FIELDS = [
  {
    type: 'tel',
    name: 'mobileNumber',
    placeholder: 'Mobile Number',
    label: 'Mobile Number',
    className:
      'relative mb-2 mt-2 appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-sm shadow-sm py-2 px-4 text-gray-900 placeholder-gray-500 focus:outline-none focus:border-black focus:ring-1 focus:ring-black',
    labelClassName: 'text-gray-700 text-sm dark:text-black',
    required: true,
    disabled: false,
    max: 10,
    handleChange: (e: any, item: any, context: any) => {
      const regex = /^[0-9\s]*$/
      if (regex.test(e?.target.value.toString())) {
        context.setFieldValue(
          LOGIN_NEW_MODAL_FORM_FIELDS[0]?.name,
          e.target.value
        )
      }
    },
  },
  {
    type: 'tel',
    name: 'otp',
    placeholder: 'OTP',
    label: 'OTP',
    className:
      'relative mb-2 mt-2 appearance-none min-w-0 w-full bg-white border border-gray-300 rounded-sm shadow-sm py-2 px-4 text-gray-900 placeholder-gray-500 focus:outline-none focus:border-black focus:ring-1 focus:ring-black',
    labelClassName: 'text-gray-700 text-sm dark:text-black',
    required: false,
    disabled: false,
    max: 6,
  },
]

export const generateLoginModalFooter = (user: any) => {
  return (
    <p className="text-xs dark:text-gray-500">
      By continuing, I agree to the{' '}
      <Link
        href="/terms-and-conditions"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="text-orange" onClick={() => policyPopupEvent(user)}>
          Terms of use
        </span>
      </Link>{' '}
      &{' '}
      <Link href="/privacy-policy" target="_blank" rel="noopener noreferrer">
        <span className="text-orange">Privacy Policy</span>
      </Link>
    </p>
  )
}

export const getUserName = (data: any) => {
  if (data?.firstName) {
    return data?.firstName
  } else if (data?.mobile) {
    return data?.mobile
  } else if (data?.username) {
    return data?.username
  } else if (data?.telephone) {
    return data?.telephone
  } else {
    return null
  }
}

export const getUserNumber = (user: any) => {
  if(!user?.userId) return
  if (user?.mobile) {
    return user?.mobile
  } else if (user?.telephone) {
    return user?.telephone
  } else if (user?.phone) {
    return user?.phone
  } else {
    return ''
  }
}